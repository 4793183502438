import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { controlValues, Duration, durationAsMinutes, ErrorService, formatMinutesHHMM, formatTime, Instant, localDateTimeToInstant, Minutes, minutesBetween, parseLocalTime, PilotId, SpinnerButtonComponent, toLocalDateTime } from "common";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { PilotageId, PilotType, SchedulingEndpoint } from "apina-frontend";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { brandedMinutes } from "../../domain/id-parsing";
import { LocalTime } from "@js-joda/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { A11yModule } from "@angular/cdk/a11y";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";

const DELTA_TIME_PATTERN = /^(\d\d)([0-5]\d)$/;

@Component({
    selector: 'app-alarm-clock-editor',
    templateUrl: './alarm-clock-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        A11yModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        SpinnerButtonComponent,
        ReactiveFormsModule,
        VerticalFormComponent,
    ]
})
export class AlarmClockEditorComponent {

    form = new FormGroup({
        difference: new FormControl<string>("", {
            validators: [Validators.required, Validators.pattern(DELTA_TIME_PATTERN)],
            nonNullable: true
        }),

        time: new FormControl<string>("", {
            validators: [Validators.required],
            nonNullable: true
        }),
    });

    readonly saving = signal(false);

    constructor(private readonly dialogRef: MatDialogRef<AlarmClockEditorComponent, AlarmClockEditorResult>,
                private readonly schedulingEndpoint: SchedulingEndpoint,
                private readonly errorService: ErrorService,
                @Inject(MAT_DIALOG_DATA) private readonly params: AlarmClockEditorComponentParams) {

        controlValues(this.form.controls.difference).subscribe(diff => {
            const minutes = parseMinutes(diff);
            if (minutes != null) {
                const absoluteTime = this.params.startTime.minus(Duration.ofMinutes(minutes));
                this.form.controls.time.reset(formatTime(absoluteTime), {emitEvent: false});
            } else {
                this.form.controls.time.reset("-", {emitEvent: false});
            }
        });

        controlValues(this.form.controls.time).subscribe(s => {
            const localTime = parseLocalTime(s);
            if (localTime != null) {
                const minutes = resolveMinutesBetween(this.params.startTime, localTime);
                this.form.controls.difference.reset(formatMinutesHHMM(minutes), {emitEvent: false});
            } else {
                this.form.controls.difference.reset("-", {emitEvent: false});
            }
        });

        if (params.preparationTime != null) {
            this.form.controls.difference.reset(formatMinutesHHMM(durationAsMinutes(params.preparationTime)));
        } else {
            this.form.controls.difference.reset("");
        }
    }

    async save(): Promise<void> {
        const alarmTimeDifference = parseMinutes(this.form.controls.difference.value);
        if (alarmTimeDifference == null)
            return;

        this.saving.set(true);

        try {
            await this.schedulingEndpoint.updateAlarmTime(this.params.pilotageId, {
                pilotId: this.params.pilotId,
                pilotType: this.params.pilotType,
                alarmTimeDifference
            });
            this.saving.set(false);
            this.dialogRef.close(alarmTimeDifference);

        } catch (e) {
            this.saving.set(false);
            this.errorService.showUpdateError(e);
        }
    }
}

function parseMinutes(value: string): Minutes | null {
    if (value === '')
        return null;

    const match = DELTA_TIME_PATTERN.exec(value);
    if (match) {
        const h = parseInt(match[1]);
        const m = parseInt(match[2]);
        return brandedMinutes(h * 60 + m);

    } else {
        return null;
    }
}

function resolveMinutesBetween(reference: Instant, time: LocalTime): Minutes {
    const localReference = toLocalDateTime(reference);

    let resolvedTime = localReference.with(time);
    if (resolvedTime.isAfter(localReference))
        resolvedTime = resolvedTime.minusDays(1);

    return minutesBetween(localDateTimeToInstant(resolvedTime), reference);
}

export interface AlarmClockEditorComponentParams {
    pilotageId: PilotageId;
    pilotId: PilotId;
    pilotType: PilotType;
    startTime: Instant;
    preparationTime: Duration | null;
}

export type AlarmClockEditorResult = Minutes;
