import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { OfficeId, PilotageEndpoint, PilotageId } from "apina-frontend";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HelsinkiDatePipe, Instant } from "common";
import { routeDescription } from "../../domain/routes";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { SelectCustomerOfficeComponent } from "../../common/select-customer-office/select-customer-office.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";

@Component({
    templateUrl: "./change-customer.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonDialogFormComponent,
        SelectCustomerOfficeComponent,
        HelsinkiDatePipe,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        VerticalFormComponent,
    ]
})
export class ChangeCustomerComponent implements CommonDialogFormDelegate {

    readonly form = new FormGroup({
        customerId: new FormControl<OfficeId | null>(null),
        billingCustomerId: new FormControl<OfficeId | null>(null)
    });

    readonly viewData = signal<ChangeCustomerViewData | undefined>(undefined);

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly params: ChangeCustomerComponentParams,
        private readonly pilotageEndpoint: PilotageEndpoint,
    ) {

        pilotageEndpoint.getPilotageDetails(params.pilotageId).then(pilotage => {
            this.form.reset({
                customerId: pilotage.broker?.id ?? null,
                billingCustomerId: pilotage.billingCustomer?.id ?? null
            });

            this.viewData.set({
                vesselName: pilotage.vessel.name,
                routeDescription: routeDescription(pilotage.route),
                startTime: pilotage.startTime
            });
        });
    }

    doSave(): Promise<void> {
        return this.pilotageEndpoint.updateCustomer(this.params.pilotageId, this.form.controls.customerId.value, this.form.controls.billingCustomerId.value);
    }
}

export interface ChangeCustomerComponentParams {
    pilotageId: PilotageId;
}

interface ChangeCustomerViewData {
    vesselName: string;
    routeDescription: string;
    startTime: Instant;
}
