@if (data.value(); as data) {
    <h3 mat-dialog-title>Peru luotsaus alukselle {{ data.vesselName }}?</h3>

    <mat-dialog-content>
        @if (data.incursCancelFee) {
            <div class="flex flex-col gap-4 max-w-[480px]">
                <div class="text-orange">
                    Peruttu luotsaus laskutetaan, koska peruminen on tehty tilausajan umpeutumisen jälkeen.
                </div>

                <mat-form-field>
                    <mat-label>Syy</mat-label>
                    <mat-select [formControl]="form.controls.reason">

                        @for (reason of reasons; track $index) {
                            <mat-option [value]="reason">{{ reason }}</mat-option>
                        }
                        <mat-option [value]="REASON_OTHER">Muu, mikä?</mat-option>
                    </mat-select>
                </mat-form-field>

                @if (form.controls.reason.value === REASON_OTHER) {
                    <app-text-field label="Syy" [control]="form.controls.reasonOther"/>
                }
            </div>

        } @else {
            <p>
                Luotsausta ei laskuteta.
            </p>
        }

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="basic" [mat-dialog-close]="false">Peruuta</button>
        <app-spinner-button [color]="data.usableAsBasePilotage ? undefined : 'primary'"
                            [disabled]="form.invalid"
                            [active]="progress()"
                            (spinnerClick)="accept(data, false)">
            Peru luotsaus
        </app-spinner-button>
        @if (data.usableAsBasePilotage) {
            <app-spinner-button color="primary"
                                [disabled]="form.invalid"
                                [active]="progress()"
                                (spinnerClick)="accept(data, true)">
                Peru ja luo uusi
            </app-spinner-button>
        }
    </mat-dialog-actions>
} @else {
    <mat-spinner class="default-spinner"/>
}
