import { ChangeDetectionStrategy, Component, computed, effect, EventEmitter, input, Output, signal } from "@angular/core";
import { PilotageObservationEditForm, PilotageObservationEndpoint, PilotageObservationForEdit } from "apina-frontend";
import { ErrorService, HelsinkiDatePipe, Instant } from "common";
import { getPilotageName } from "../../taskboard/common/pilotage-identification";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { TextareaFieldComponent } from "../../forms/textarea-field/textarea-field.component";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";

@Component({
    selector: 'app-pilotage-observation',
    templateUrl: './pilotage-observation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HelsinkiDatePipe,
        MatButtonModule,
        ReactiveFormsModule,
        VerticalFormComponent,
        TextareaFieldComponent,
        DatetimeFieldComponent,
    ]
})
export class PilotageObservationComponent {

    readonly updating = signal(false);
    readonly editForm = new FormGroup({
        deadline: new FormControl<Instant | null>(null, Validators.required),
        notes: new FormControl<string>("", {nonNullable: true}),
    });

    observation = input.required<PilotageObservationForEdit>();
    showPilotageInfo = input(true);

    readonly pilotageName = computed(() => {
        const data = this.observation();
        return getPilotageName(data.vesselName, data.startCode, data.endCode);
    });

    readonly alreadyPersisted = computed(() => this.observation().editForm != null);

    @Output() closed = new EventEmitter<void>();

    constructor(
        private readonly endpoint: PilotageObservationEndpoint,
        private readonly errorService: ErrorService
    ) {
        effect(() => {
            const editForm = this.observation().editForm;
            if (editForm != null)
                this.editForm.reset(editForm);
        });
    }

    delete(): void {
        this.updating.set(true);

        this.endpoint.deleteObservation(this.observation().pilotageId).then(
            () => this.close(),
            e => {
                this.updating.set(false);
                return this.errorService.showUpdateError(e);
            }
        );
    }

    save(): void {
        this.updating.set(true);

        const data: PilotageObservationEditForm = {
            deadline: this.editForm.controls.deadline.value,
            notes: this.editForm.controls.notes.value
        };
        this.endpoint.savePilotageObservation(this.observation().pilotageId, data).then(
            () => this.close(),
            e => {
                this.updating.set(false);
                return this.errorService.showUpdateError(e);
            }
        );
    }

    close(): void {
        this.closed.next();
    }
}
