import { ChangeDetectionStrategy, Component, Inject, resource, Resource } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PilotageDetails, PilotageEndpoint, PilotageId, UpdateDraftParams } from "apina-frontend";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AirDraft, Draft } from "common";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { createDraftControls, EditDraftFieldsComponent } from "../../common/edit-draft-fields/edit-draft-fields.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";

@Component({
    templateUrl: './set-missing-draft.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonDialogFormComponent,
        EditDraftFieldsComponent,
        MatProgressSpinnerModule,
        VerticalFormComponent,
    ]
})
export class SetMissingDraftComponent implements CommonDialogFormDelegate {

    readonly form: FormGroup<SetMissingDraftForm>;
    readonly pilotage: Resource<PilotageDetails | undefined>;

    constructor(@Inject(MAT_DIALOG_DATA) readonly params: SetMissingDraftComponentParams,
                readonly pilotageEndpoint: PilotageEndpoint) {

        this.form = new FormGroup({
            ...createDraftControls()
        });

        this.pilotage = resource({ loader: () => pilotageEndpoint.getPilotageDetails(params.pilotageId)});
    }

    doSave(): Promise<unknown> {
        const values = this.form.value;
        const updateParams: UpdateDraftParams = {
            draft: values.draft ?? null,
            draftAft: values.draftAft ?? null,
            draftFore: values.draftFore ?? null,
            airDraft: values.airDraft ?? null,
        };
        const pilotageId = this.params.pilotageId;
        return this.pilotageEndpoint.updateDraft(pilotageId, updateParams);
    }
}

export interface SetMissingDraftComponentParams {
    pilotageId: PilotageId;
    airDraft: AirDraft | null;
}

interface SetMissingDraftForm {
    draft: FormControl<Draft | null>;
    draftAft: FormControl<Draft | null>;
    draftFore: FormControl<Draft | null>;
    airDraft: FormControl<AirDraft | null>;
}
