import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { PilotageEndpoint, PilotageId, PilotageScheduleSource } from "apina-frontend";
import { currentDate, Instant, localDateTimeToInstant, MyMatDateTimePickerComponent } from "common";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { ScheduleSourceFieldComponent } from "../../forms/schedule-source-field/schedule-source-field.component";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";

@Component({
    templateUrl: "./update-anchorage-arrival-time.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatFormFieldModule,
        MatIconModule,
        MyMatDateTimePickerComponent,
        CommonDialogFormComponent,
        ReactiveFormsModule,
        InputRowComponent,
        ScheduleSourceFieldComponent,
        VerticalFormComponent,
    ]
})
export class UpdateAnchorageArrivalTimeComponent implements CommonDialogFormDelegate {

    readonly params = inject<UpdateAnchorageArrivalTimeParams>(MAT_DIALOG_DATA);
    private readonly pilotageEndpoint = inject(PilotageEndpoint);

    readonly startOfDay = localDateTimeToInstant(currentDate().atStartOfDay());

    readonly form = new FormGroup({
        estimatedAnchorageArrivalTime: new FormControl<Instant | null>(this.params.estimatedAnchorageArrivalTime, Validators.required),
        scheduleSource: new FormControl<PilotageScheduleSource | null>(null, Validators.required)
    });

    doSave(): Promise<unknown> {
        return this.pilotageEndpoint.updateEstimatedAnchorageArrivalTime(this.params.pilotageId, {
            estimatedAnchorageArrivalTime: this.form.controls.estimatedAnchorageArrivalTime.value!,
            scheduleSource: this.form.controls.scheduleSource.value!
        });
    }
}

export interface UpdateAnchorageArrivalTimeParams {
    pilotageId: PilotageId;
    vesselName: string,
    estimatedAnchorageArrivalTime: Instant | null;
}
