import { ChangeDetectionStrategy, Component, computed, inject, Inject, resource, Resource, signal } from '@angular/core';
import { ContinuationChainInfo, FindRouteDto, FullRouteChainInfo, PilotageChainContinuationChange, PilotageChainEditData, PilotageChainEditLegInfo, PilotageChainEndpoint, PilotageChainId, PilotageChainUpdateData, PilotageChainUpdateResult, PilotageId, RouteChainId, RouteEndpoint, RouteId } from "apina-frontend";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DialogsService, ErrorService, insertAt, removeAt, SpinnerButtonComponent } from "common";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { RouteSelectedCallback, SelectRouteDialog, SelectRouteParams } from "../../routes/select-route/select-route-dialog.component";
import { SelectRouteChainDialog, SelectRouteChainParams } from "../../routes/select-route-chain/select-route-chain-dialog.component";

@Component({
    selector: 'app-edit-pilotage-chain',
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule,
        SpinnerButtonComponent,
        TranslateModule,
        MatProgressSpinner,
    ],
    templateUrl: './edit-pilotage-chain.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "class": "!block min-w-[800px]"
    }
})
export class EditPilotageChainComponent {

    readonly chainData = signal<PilotageChainEditData | undefined>(undefined);
    readonly legs = signal<LocalPilotageChainEditLegInfo[]>([]);
    readonly continuationChain = signal<ContinuationChainInfo | null>(null);
    private readonly continuationChainUpdate = signal<PilotageChainContinuationChange>({type: "KeepChain"});
    private readonly routeChainId = signal<RouteChainId | null>(null);
    readonly modified = signal(false);
    readonly saving = signal(false);

    readonly adhoc = computed(() => this.routeChainId() == null);
    readonly valid = computed(() => validChain(this.legs(), this.continuationChain()));
    readonly mayAddBeforeChain = computed(() => {
        const legs = this.legs();
        return (legs.length === 0 || legs[0].editable);
    });

    private readonly routes: Resource<FindRouteDto[]>;
    private readonly routeChains: Resource<FullRouteChainInfo[]>;
    private readonly matDialog = inject(MatDialog);

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly params: EditPilotageChainParams,
        private readonly pilotageChainEndpoint: PilotageChainEndpoint,
        routeEndpoint: RouteEndpoint,
        private readonly dialogsService: DialogsService,
        private readonly errorService: ErrorService,
        private readonly dialogRef: MatDialogRef<EditPilotageChainComponent>,
    ) {
        pilotageChainEndpoint.findChainForEditing(params.pilotageChainId).then(data => {
            this.chainData.set(data);

            this.legs.set(data.legs);
            this.continuationChain.set(data.continuationChain);
            this.routeChainId.set(data.routeChainId);
        });

        this.routes = resource({loader: () => routeEndpoint.getRoutes()});
        this.routeChains = resource({loader: () => routeEndpoint.getFullRouteChains()});
    }

    addLeg(startCode: string, index: number): void {
        this.openRouteSelectionWindow(startCode, id => {
            const newLeg = this.newLegInfo(id);
            if (newLeg !== undefined) {
                this.legs.update(legs => insertAt(legs, index, newLeg));
                this.routeChainId.set(null);
                this.modified.set(true);
            }
        });
    }

    async removeLeg(index: number): Promise<void> {
        const remove = await this.dialogsService.showConfirmationQuestionDialog({
            question: "Haluatko varmasti poistaa reitin ketjusta?",
            confirmText: "Poista",
        });
        if (remove) {
            this.legs.update(legs => removeAt(legs, index));
            this.routeChainId.set(null);
            this.modified.set(true);
        }
    }

    changeRoute(startCode: string, index: number): void {
        this.openRouteSelectionWindow(startCode, id => {
            const newLeg = this.newLegInfo(id);
            if (newLeg !== undefined) {
                const newLegs = [...this.legs()];
                newLegs[index] = newLeg;

                this.legs.set(newLegs);
                this.routeChainId.set(null);
                this.modified.set(true);
            }
        });
    }

    private openRouteSelectionWindow(start: string, routeSelected: RouteSelectedCallback): void {
        this.matDialog.open<SelectRouteDialog, SelectRouteParams>(SelectRouteDialog, {
            data: {start, routeSelected}
        });
    }

    selectContinuationChain(): void {
        const legs = this.legs();
        if (legs.length === 0)
            return;

        this.matDialog.open<SelectRouteChainDialog, SelectRouteChainParams>(SelectRouteChainDialog, {
            data: {
                start: legs[legs.length - 1].endCode,
                routeChainSelected: routeChainId => {
                    const newChain = this.routeChains.value()?.find(it => it.id === routeChainId);
                    if (newChain !== undefined) {
                        const chainStart = newChain.legs[0].start;
                        const chainEnd = newChain.legs[newChain.legs.length - 1].end;
                        this.continuationChain.set({routeChainId, startCode: chainStart.code, endCode: chainEnd.code});
                        this.continuationChainUpdate.set({type: "ChangeChain", routeChainId});
                        this.modified.set(true);
                    }
                }
            }
        });
    }

    removeContinuationChain(): void {
        this.modified.set(true);
        this.continuationChain.set(null);
        this.continuationChainUpdate.set({type: "CancelChain"});
    }

    save(): void {
        const data: PilotageChainUpdateData = {
            legs: this.legs().map(it => ({pilotageId: it.pilotageId, routeId: it.routeId})),
            routeChainId: this.routeChainId(),
            continuationChain: this.continuationChainUpdate(),
        };

        this.saving.set(true);
        this.pilotageChainEndpoint.saveChain(this.params.pilotageChainId, data).then(
            result => {
                this.saving.set(false);
                switch (result) {
                    case PilotageChainUpdateResult.SUCCESS:
                        this.dialogRef.close(true);
                        break;
                    case PilotageChainUpdateResult.INVALID_CHAIN_STRUCTURE:
                        this.errorService.showUpdateError("Ketjun rakenne on virheellinen, jokaisen luotsauksen reitin pitää lähteä samasta pisteetä, johon edellinen luotsaus päättyy.");
                        break;
                    case PilotageChainUpdateResult.PRECEDING_CHAIN_ERROR:
                        this.errorService.showUpdateError("Muokattavana olevan luotsausketjun lähtöpiste on eri, kuin edeltävän luotsausketjun päätepiste.");
                        break;
                    case PilotageChainUpdateResult.CONTINUATION_CHAIN_ERROR:
                        this.errorService.showUpdateError("Muokattavana olevan luotsausketjun päätepiste on eri, kuin jatkoketjun lähtöpiste.");
                        break;
                }
            },
            e => {
                this.saving.set(false);
                this.errorService.showUpdateError(e);
            }
        );
    }

    private newLegInfo(routeId: RouteId): LocalPilotageChainEditLegInfo | undefined {
        const route = this.routes.value()?.find(it => it.id === routeId);
        if (route === undefined) return undefined;

        return {
            routeId: route.id as RouteId,
            pilotageId: null,
            name: route.name,
            startCode: route.startCode,
            endCode: route.endCode,
            editable: true,
        };
    }
}

export interface EditPilotageChainParams {
    pilotageChainId: PilotageChainId;
}

function validChain(legs: LocalPilotageChainEditLegInfo[], continuationChain: ContinuationChainInfo | null): boolean {
    if (legs.length === 0) return false;

    for (let i = 1; i < legs.length; i++)
        if (legs[i - 1].endCode !== legs[i].startCode)
            return false;

    if (continuationChain != null && legs[legs.length - 1].endCode !== continuationChain.startCode)
        return false;

    return true;
}

interface LocalPilotageChainEditLegInfo extends Omit<PilotageChainEditLegInfo, "pilotageId"> {
    pilotageId: PilotageId | null;
}
