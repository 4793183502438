import { ChangeDetectionStrategy, Component, computed, Inject, signal } from "@angular/core";
import { PilotageDraftApproval, PilotageEndpoint, PilotageId, PilotageScheduleChangeId, PilotageScheduleChangeTosApproval, PilotageScheduleSource, SevereTosViolation, TermsOfServiceInfo, TermsOfServiceResultRow, TosConflictReason } from "apina-frontend";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { capitalize, DialogsService, Duration, formatDateTimeWithYear, HelsinkiDatePipe, Instant, PILOTAGE_STATE_FINNISH_NAMES, pilotageScheduleSourceNames, simplifyUsername } from "common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DurationPipe } from "../../common/pipes/duration.pipe";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { tosConflictReasonLabels } from "../../domain/terms-of-service";

@Component({
    templateUrl: './terms-of-service.component.html',
    styles: `
        :host {
            display: block;
            max-width: 800px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DurationPipe,
        HelsinkiDatePipe,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
    ]
})
export class TermsOfServiceComponent {

    private readonly tos = signal<TermsOfServiceInfo | null>(null);
    readonly rows = computed(() => this.tos()?.rows?.map(it => ChangeRow.fromRow(it)));
    readonly mayAccept = computed(() => this.tos()?.mayAccept ?? false);
    readonly severeTosViolation = computed(() => this.tos()?.severeTosViolation);
    readonly mayMarkAsSevere = computed(() => this.severeTosViolation() == null && (this.tos()?.mayMarkAsSevere ?? false));
    readonly draftViolation = computed(() => this.tos()?.draftViolation);

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly params: TermsOfServiceComponentParams,
        private readonly dialogsService: DialogsService,
        private readonly pilotageEndpoint: PilotageEndpoint,
    ) {
        pilotageEndpoint.getTermsOfServiceInformation(params.pilotageId).then(tos => this.tos.set(tos));
    }

    async resetTos(change: ChangeRow): Promise<void> {
        const changeId = change.changeId;

        const tos = await this.dialogsService.showMultiLineInputDialog({
            label: "Anna syy hyväksynnälle",
            confirmText: "Ok"
        }, reason => {
            if (change.missing) {
                return this.pilotageEndpoint.markPreviousMissingChangeAsApproved(changeId, {reason});
            } else {
                return this.pilotageEndpoint.markAsApproved(changeId, {reason});
            }
        });

        if (tos)
            this.tos.set(tos);
    }

    async approveDraftViolation(pilotageId: PilotageId): Promise<void> {

        const tos = await this.dialogsService.showMultiLineInputDialog({
            label: "Anna syy hyväksynnälle",
            confirmText: "Ok"
        }, reason =>
            this.pilotageEndpoint.markDraftViolationAsApproved(pilotageId, {reason})
        );

        if (tos)
            this.tos.set(tos);

    }

    async markAsSevere(): Promise<void> {
        const tos = await this.dialogsService.showMultiLineInputDialog({
                label: "Aseta räikeästi palveluehtojen vastaiseksi",
                extraText: "Kirjoita kuvaus miksi tilaus on räikeästi palveluehtojen vastainen.",
                confirmText: "Ok"
            }, description =>
                this.pilotageEndpoint.markSevereTosViolation(this.params.pilotageId, {description})
        );

        if (tos)
            this.tos.set(tos);
    }

    violationTooltip(violation: SevereTosViolation): string {
        return `${formatDateTimeWithYear(violation.createdAt)} ${simplifyUsername(violation.author)}\n${violation.description}`;
    }

    protected readonly approvedDraftViolationTooltip = approvedDraftViolationTooltip;
}

class ChangeRow {
    readonly minutes: Duration | null;
    readonly className: string;
    readonly mayApprove: boolean;
    readonly sourceName: string;
    readonly missing: boolean;

    constructor(
        type: "Change" | "MissingNotice" | "Notice" | "Order",
        readonly changeId: PilotageScheduleChangeId,
        readonly time: Instant | null,
        readonly phase: string,
        readonly eta: Instant | null,
        source: PilotageScheduleSource | null,
        readonly tooltip: string,
        valid: boolean,
        tosApproval: PilotageScheduleChangeTosApproval | null,
    ) {
        this.minutes = (time != null && eta != null) ? Duration.between(time, eta) : null;
        this.className = type === "Change" ? "!text-black"
            : valid ? "!text-tos-green"
            : (tosApproval != null) ? "!text-tos-green italic"
            : "!text-tos-red";
        this.mayApprove = !valid && tosApproval == null;
        this.sourceName = source != null ? pilotageScheduleSourceNames[source] : "-";
        this.missing = type === "MissingNotice";
    }

    static fromRow(row: TermsOfServiceResultRow): ChangeRow {
        switch (row.type) {
            case "Change":
                return new ChangeRow(row.type, row.changeId, row.createdAt, capitalize(PILOTAGE_STATE_FINNISH_NAMES[row.state]), row.eta, row.source, "", true, null);
            case "MissingNotice":
                return new ChangeRow(row.type, row.nextChangeId, null, `Ennakko ${row.noticeHours}h`, null, null, approvalTooltip(row.tosApproval) ?? "Ennakko puuttuu", false, row.tosApproval);
            case "Notice":
                return new ChangeRow(row.type, row.changeId, row.createdAt, `Ennakko ${row.noticeHours}h`, row.eta, row.source, approvalTooltip(row.tosApproval) ?? conflictsTooltip(row.conflicts), row.conflicts.length === 0, row.tosApproval);
            case "Order":
                return new ChangeRow(row.type, row.changeId, row.createdAt, "Tilaus", row.eta, row.source, approvalTooltip(row.tosApproval) ?? conflictsTooltip(row.conflicts), row.conflicts.length === 0, row.tosApproval);
        }
    }
}

function approvalTooltip(approval: PilotageScheduleChangeTosApproval | null): string | null {
    if (approval != null)
        return `${formatDateTimeWithYear(approval.time)} ${simplifyUsername(approval.author)}\n${approval.reason}`;
    else
        return null;
}

function approvedDraftViolationTooltip(approval: PilotageDraftApproval | null): string {
    if (approval != null)
        return `${formatDateTimeWithYear(approval.time)} ${simplifyUsername(approval.author)}\n${approval.reason}`;
    else
        return "";
}

function conflictsTooltip(conflicts: TosConflictReason[]): string {
    return conflicts.map(c => tosConflictReasonLabels[c]).join("\n");
}

export interface TermsOfServiceComponentParams {
    pilotageId: PilotageId;
    vesselName: string;
    route: string;
}
