import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Inject, resource, Resource, Signal, signal } from "@angular/core";
import { PilotageAlertData, PilotageAlertEditData, PilotageAlertEndpoint, PilotageAlertId, PilotageDetails, PilotageEndpoint, PilotageId } from "apina-frontend";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { arrayOfNumbersInRange, Duration, durationAsHoursAndMinutes, ErrorService, HelsinkiDatePipe, Instant, Minutes } from "common";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { brandedMinutes } from "../../domain/id-parsing";
import { DurationPipe } from "../../common/pipes/duration.pipe";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TextFieldModule } from "@angular/cdk/text-field";

@Component({
    templateUrl: "./manage-pilotage-alerts.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DurationPipe,
        HelsinkiDatePipe,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatDialogModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        TextFieldModule,
    ],
    host: {
        "class": "!block min-w-[600px]",
    }
})
export class ManagePilotageAlertsComponent {

    private readonly alerts: Resource<PilotageAlertData[]>;
    private readonly editedAlertId = signal<PilotageAlertId | null>(null);
    private readonly newAlert = signal<Alert | null>(null);
    readonly viewData: Signal<ManagePilotageViewData | null>;

    constructor(
        pilotageEndpoint: PilotageEndpoint,
        private readonly pilotageAlertEndpoint: PilotageAlertEndpoint,
        private readonly errorService: ErrorService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private readonly params: ManagePilotageAlertsComponentParams,
    ) {
        const pilotageSignal = resource({ loader: () => pilotageEndpoint.getPilotageDetails(params.pilotageId)});

        this.alerts = resource({
            loader: () => this.pilotageAlertEndpoint.findAlertsForPilotage(this.params.pilotageId)
        });

        this.viewData = computed(() => {
            const pilotage = pilotageSignal.value();
            const alerts = this.alerts.value();

            if (pilotage == null || alerts === undefined)
                return null;

            const oldAlerts = alerts.map(it => new Alert(it.id, it.title, pilotage.startTime, Duration.ofMinutes(it.alarmTimeDifference), a => this.onToggle(a, pilotage.id), it.dismissed));
            const newAlert = this.newAlert();
            const editedAlertId = this.editedAlertId();

            return {
                pilotage,
                inEditMode: newAlert != null || editedAlertId != null,
                selectableAlertDeltas: arrayOfNumbersInRange(0, 60).map(i => new AlertDelta(i * 5, pilotage.eta)),
                alerts: (newAlert != null) ? [...oldAlerts, newAlert] : oldAlerts,
                editedAlertId
            };
        });
    }

    formatMinutes(minutes: number): string {
        return durationAsHoursAndMinutes(Duration.ofMinutes(minutes));
    }

    addAlert(pilotage: PilotageDetails): void {
        this.newAlert.set(new Alert(null, "", pilotage.eta, Duration.ofMinutes(0), a => this.onToggle(a, pilotage.id)));
    }

    startEditing(alert: Alert): void {
        alert.startEditing();
        this.editedAlertId.set(alert.id);
    }

    private onToggle(alert: Alert, pilotageId: PilotageId): void {
        const value = alert.completed.value;

        this.pilotageAlertEndpoint.toggleCompletion(pilotageId, alert.id!, value).then(
            () => {
                this.notifyChange();
            },
            e => {
                alert.completed.setValue(!value, {emitEvent: false});
                this.changeDetectorRef.markForCheck();
                this.errorService.showUpdateError(e);
            }
        );
    }

    toggleCompletion(alert: Alert): void {
        alert.completed.setValue(!alert.completed.value);
    }

    cancelEditing(): void {
        this.newAlert.set(null);
        this.editedAlertId.set(null);
    }

    async save(alert: Alert, pilotageId: PilotageId): Promise<void> {
        try {
            const data = alert.toPilotageAlertEditData();

            if (alert.id == null)
                await this.pilotageAlertEndpoint.createAlertForPilotage(pilotageId, data);
            else
                await this.pilotageAlertEndpoint.updateAlert(pilotageId, alert.id, data);

            this.editedAlertId.set(null);
            this.newAlert.set(null);
            this.notifyChange();
            this.alerts.reload();

        } catch (e) {
            this.errorService.showUpdateError(e);
        }
    }

    private notifyChange(): void {
        if (this.params.onChange)
            this.params.onChange();
    }
}

class AlertDelta {

    readonly duration: Duration;
    readonly time: Instant;

    constructor(readonly minutes: number, eta: Instant) {
        this.duration = Duration.ofMinutes(minutes);
        this.time = eta.minus(this.duration);
    }
}

class Alert {

    readonly titleControl: FormControl<string>;
    readonly alarmTimeDifferenceControl: FormControl<Minutes>;
    readonly completed: FormControl<boolean>;

    constructor(
        readonly id: PilotageAlertId | null,
        readonly title: string,
        private pilotageStartTime: Instant,
        readonly alarmTimeDifference: Duration,
        onToggle: (a: Alert) => void,
        completed: boolean = false,
    ) {
        this.titleControl = new FormControl(title, {validators: [Validators.required, Validators.maxLength(128)], nonNullable: true});
        this.alarmTimeDifferenceControl = new FormControl(brandedMinutes(alarmTimeDifference.toMinutes()), {nonNullable: true});

        this.completed = new FormControl(completed, {nonNullable: true});
        this.completed.valueChanges.subscribe(() => onToggle(this));
    }

    startEditing(): void {
        this.titleControl.reset(this.title);
        this.alarmTimeDifferenceControl.reset(brandedMinutes(this.alarmTimeDifference.toMinutes()));
    }

    toPilotageAlertEditData(): PilotageAlertEditData {
        return {
            title: this.titleControl.value,
            alarmTimeDifference: this.alarmTimeDifferenceControl.value,
        };
    }

    get time(): Instant {
        return this.pilotageStartTime.minus(this.alarmTimeDifference);
    }
}

export interface ManagePilotageAlertsComponentParams {
    pilotageId: PilotageId;
    onChange?: () => void;
}

interface ManagePilotageViewData {
    pilotage: PilotageDetails;
    alerts: Alert[];
    selectableAlertDeltas: AlertDelta[];
    inEditMode: boolean;
    editedAlertId: PilotageAlertId | null;
}
