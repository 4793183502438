import { ChangeDetectionStrategy, Component, computed, inject, resource, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { controlValuesSignal, enableWhen, ErrorService, SpinnerButtonComponent } from "common";
import { CancelPilotageData, PilotageEndpoint, PilotageId } from "apina-frontend";
import { PilotageService } from "../pilotage.service";
import { NewPilotageSourceMode } from "../new-pilotage/new-pilotage-from.resolver";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { TextFieldComponent } from "../../forms/text-field/text-field.component";
import { MatFormFieldModule } from "@angular/material/form-field";

const REASON_OTHER = "OTHER";

@Component({
    templateUrl: './cancel-pilotage-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatProgressSpinner,
        MatSelectModule,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        TextFieldComponent,
    ]
})
export class CancelPilotageDialogComponent {

    private readonly dialogRef = inject(MatDialogRef<CancelPilotageDialogComponent>);
    private readonly errorService = inject(ErrorService);
    private readonly pilotageEndpoint = inject(PilotageEndpoint);
    private readonly pilotageService = inject(PilotageService);
    private readonly params = inject<CancelPilotageDialogComponentParams>(MAT_DIALOG_DATA);

    readonly reasons = [
        "Aluksen tekninen vika",
        "Viivästyminen lastinkäsittelyn vuoksi",
        "Ulkopuolisten toimijoiden saatavuus (esim. hinaajat)",
        "Ei vapaata laituripaikkaa",
    ];

    readonly REASON_OTHER = REASON_OTHER;

    readonly form = new FormGroup({
        reason: new FormControl("", {nonNullable: true, validators: Validators.required}),
        reasonOther: new FormControl("", {nonNullable: true, validators: Validators.required}),
    });

    readonly data = resource({loader: () => this.pilotageEndpoint.getCancelData(this.params.pilotageId)});
    readonly progress = signal(false);

    constructor() {
        const reason = controlValuesSignal(this.form.controls.reason);

        const hasCancelFee = computed(() => this.data.value()?.incursCancelFee === true);
        enableWhen(hasCancelFee, this.form.controls.reason);
        enableWhen(computed(() => hasCancelFee() && reason() === REASON_OTHER), this.form.controls.reasonOther);
    }

    async accept(data: CancelPilotageData, newPilotage: boolean): Promise<void> {
        this.progress.set(true);

        try {
            if (data.incursCancelFee) {
                const value = this.form.getRawValue();

                await this.pilotageEndpoint.cancelPilotage(this.params.pilotageId, {
                    reason: (value.reason === REASON_OTHER) ? value.reasonOther : value.reason,
                });
            } else
                await this.pilotageEndpoint.removePilotage(this.params.pilotageId);

            this.dialogRef.close(true);
            if (newPilotage)
                this.pilotageService.openCreateNewPilotageDialog({from: this.params.pilotageId, mode: NewPilotageSourceMode.CANCELLED});
        } catch (e) {
            this.errorService.showUpdateError(e);
        } finally {
            this.progress.set(false);
        }
    }
}

export interface CancelPilotageDialogComponentParams {
    pilotageId: PilotageId;
}
