import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@Component({
    selector: 'app-transfer-fee-field',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
    ],
    templateUrl: './transfer-fee-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        "class": "block",
    }
})
export class TransferFeeFieldComponent {

    label = input.required<string>();
    control = input.required<FormControl<number | null>>();
    hint = input<string | null>(null);
}
