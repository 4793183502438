import { ChangeDetectionStrategy, Component, Inject, Resource, resource } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BerthId, PilotageEndpoint, PilotageId, PilotageRouteInfo, PilotageScheduleSource, PilotStatusOnPilotage } from "apina-frontend";
import { Instant } from "common";
import { routeDescription } from "../../domain/routes";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { BerthFieldComponent } from "../../forms/berth-field/berth-field.component";
import { ScheduleSourceFieldComponent } from "../../forms/schedule-source-field/schedule-source-field.component";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";

@Component({
    templateUrl: './reset-to-notice.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonDialogFormComponent,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        VerticalFormComponent,
        InputRowComponent,
        BerthFieldComponent,
        ScheduleSourceFieldComponent,
        DatetimeFieldComponent,
    ]
})
export class ResetToNoticeComponent implements CommonDialogFormDelegate {

    readonly title: string;
    readonly form: FormGroup<ResetToNoticeForm>;
    readonly pilotStatusOnPilotage: Resource<PilotStatusOnPilotage | undefined>;

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly params: ResetToNoticeComponentParams,
        private readonly pilotageEndpoint: PilotageEndpoint,
    ) {

        this.pilotStatusOnPilotage = resource({ loader: () => pilotageEndpoint.getPilotStatusOnPilotage(params.pilotageId)});

        this.title = `${params.vesselName}: ${routeDescription(params.route)}`;

        this.form = new FormGroup<ResetToNoticeForm>({
            eta: new FormControl(params.eta, {validators: Validators.required, nonNullable: true}),
            source: new FormControl(null, Validators.required),
            startBerthId: new FormControl(params.route.start.berth?.id ?? null),
            endBerthId: new FormControl(params.route.end.berth?.id ?? null),
        });
    }

    doSave(): Promise<void> {
        return this.pilotageEndpoint.resetToNotice(this.params.pilotageId, {
            eta: this.form.controls.eta.value,
            source: this.form.controls.source.value!,
            startBerthId: this.form.controls.startBerthId.value,
            endBerthId: this.form.controls.endBerthId.value,
        });
    }
}

interface ResetToNoticeForm {
    endBerthId: FormControl<BerthId | null>;
    eta: FormControl<Instant>;
    source: FormControl<PilotageScheduleSource | null>;
    startBerthId: FormControl<BerthId | null>;
}

export interface ResetToNoticeComponentParams {
    pilotageId: PilotageId;
    eta: Instant;
    vesselName: string;
    route: PilotageRouteInfo;
}
