@if (pilotStatusOnPilotage.value(); as pilotStatusOnPilotage) {
    @if (!pilotStatusOnPilotage.hasStartedJourney) {
        <app-common-dialog-form
            [delegate]="this"
            [dialogTitle]="title"
            saveText="Palauta ennakoksi">

            <app-vertical-form>
                <app-datetime-field label="ETA" [control]="form.controls.eta" [required]="true"/>
                <app-schedule-source-field label="Aikataulutiedon lähde" [control]="form.controls.source"/>

                <app-input-row>
                    <app-berth-field label="Alkulaituri" [control]="form.controls.startBerthId" [endpointId]="params.route.start.id"/>
                    <app-berth-field label="Loppulaituri" [control]="form.controls.endBerthId" [endpointId]="params.route.end.id"/>
                </app-input-row>
            </app-vertical-form>

        </app-common-dialog-form>
    } @else {
        <h3 mat-dialog-title>{{ title }}</h3>
        <mat-dialog-content>
            <p>Palautus ennakoksi ei ole mahdollista: luotsi on jo matkalla.</p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button [mat-dialog-close]="undefined">Sulje</button>
        </mat-dialog-actions>
    }
} @else {
    <mat-spinner class="default-spinner"/>
}
