import { ChangeDetectionStrategy, Component, Inject, Signal } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PilotageId, PilotageNoticeEndpoint, StandardNoticeDto, StandardNoticeId } from "apina-frontend";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { map, switchMap } from "rxjs/operators";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { TextareaFieldComponent } from "../../forms/textarea-field/textarea-field.component";
import { from } from "rxjs";

@Component({
    selector: 'app-pilotage-notice-editor',
    templateUrl: './pilotage-notice-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonDialogFormComponent,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        ReactiveFormsModule,
        TextFieldModule,
        VerticalFormComponent,
        TextareaFieldComponent,
    ]
})
export class PilotageNoticeEditorComponent {

    readonly viewData: Signal<PilotageNoticeEditorViewData | undefined>;

    constructor(
        pilotageNoticeEndpoint: PilotageNoticeEndpoint,
        @Inject(MAT_DIALOG_DATA) params: PilotageNoticeEditorParams,
    ) {

        this.viewData = toSignal(from(pilotageNoticeEndpoint.getNoticeEditData(params.pilotageId)).pipe(
            switchMap(editData => from(pilotageNoticeEndpoint.getPilotageStandardNotices(editData.standardNoticeId)).pipe(
                map(standardNotices => {
                    const form = new FormGroup<PilotageNoticeEditorForm>({
                        standardNoticeId: new FormControl(editData.standardNoticeId),
                        notice: new FormControl(editData.notice, {nonNullable: true}),
                        billingNotice: new FormControl(editData.billingNotice, {nonNullable: true}),
                    });
                    return ({
                        standardNotices,
                        vesselName: editData.vesselName,
                        route: editData.route,
                        form,
                        doSave: () => pilotageNoticeEndpoint.updateNotice(params.pilotageId, {
                            notice: form.controls.notice.value,
                            standardNoticeId: form.controls.standardNoticeId.value,
                            billingNotice: form.controls.billingNotice.value
                        })
                    });
                })))));
    }
}

interface PilotageNoticeEditorForm {
    standardNoticeId: FormControl<StandardNoticeId | null>;
    notice: FormControl<string>;
    billingNotice: FormControl<string>;
}

interface PilotageNoticeEditorViewData extends CommonDialogFormDelegate {
    vesselName: string;
    route: string;
    standardNotices: StandardNoticeDto[];
    form: FormGroup<PilotageNoticeEditorForm>;
}

export interface PilotageNoticeEditorParams {
    pilotageId: PilotageId;
}
