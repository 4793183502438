import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { PilotageEndpoint, PilotageId } from "apina-frontend";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { TransferFeeFieldComponent } from "../../forms/transfer-fee-field/transfer-fee-field.component";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";

@Component({
    templateUrl: './transfer-fee.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonDialogFormComponent,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        TransferFeeFieldComponent,
        VerticalFormComponent,
    ]
})
export class TransferFeeComponent implements CommonDialogFormDelegate {

    readonly form: FormGroup<{ transferFee: FormControl<number>, serviceFee: FormControl<number> }>;

    constructor(@Inject(MAT_DIALOG_DATA) private readonly params: TransferFeeComponentParams,
                private readonly pilotageEndpoint: PilotageEndpoint) {

        this.form = new FormGroup({
            transferFee: new FormControl(params.transferFees, {nonNullable: true}),
            serviceFee: new FormControl(params.serviceFees, {nonNullable: true})
        });
    }

    doSave(): Promise<void> {
        return this.pilotageEndpoint.updateFees(this.params.pilotageId, this.form.controls.transferFee.value, this.form.controls.serviceFee.value);
    }
}

export interface TransferFeeComponentParams {
    pilotageId: PilotageId;
    transferFees: number;
    serviceFees: number;
}
