@if (viewData$ | async; as viewData) {
    <app-common-dialog-form
        saveKey="common.approve"
        [delegate]="viewData"
        [dialogTitle]="viewData.title">

        <app-vertical-form>
            <mat-form-field>
                <mat-label>Luotsi</mat-label>
                <input matInput disabled value="{{ params.pilot.name.lastName }} {{ params.pilot.name.firstName }}">
            </mat-form-field>

            @if (viewData.askForPilotingMethods) {
                @if (viewData.form.controls.method.controls.pilotingMethodOnFairway.enabled) {
                    <mat-form-field>
                        <mat-label>Käytetyin luotsaustapa väylällä</mat-label>
                        <mat-select [formControl]="viewData.form.controls.method.controls.pilotingMethodOnFairway">
                            @for (m of pilotingMethods; track m.value) {
                                <mat-option [value]="m.value">{{ m.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }

                @if (viewData.form.controls.method.controls.pilotingMethodOnHarbor.enabled) {
                    <mat-form-field>
                        <mat-label>Käytetyin luotsaustapa satamassa</mat-label>
                        <mat-select [formControl]="viewData.form.controls.method.controls.pilotingMethodOnHarbor">
                            @for (m of pilotingMethodsWithCaptain; track m.value) {
                                <mat-option [value]="m.value">{{ m.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }

                @if (viewData.form.controls.method.controls.pilotingMethodOnCanalLocks.enabled) {
                    <mat-form-field>
                        <mat-label>Käytetyin luotsaustapa suluissa</mat-label>
                        <mat-select [formControl]="viewData.form.controls.method.controls.pilotingMethodOnCanalLocks">
                            @for (m of pilotingMethods; track m.value) {
                                <mat-option [value]="m.value">{{ m.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }

                @if (viewData.form.controls.method.controls.tugCount.enabled) {
                    <mat-form-field>
                        <mat-label>Hinaajien lukumäärä</mat-label>
                        <mat-select [formControl]="viewData.form.controls.method.controls.tugCount">
                            @for (m of tugCounts; track m.value) {
                                <mat-option [value]="m.value">{{ m.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
            }

            <app-input-row>
                <mat-form-field>
                    <mat-label>Luotsauksen alku</mat-label>
                    <app-mat-date-time-picker [formControl]="viewData.form.controls.pilotingStarted"></app-mat-date-time-picker>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Luotsauksen loppu</mat-label>
                    <app-mat-date-time-picker [formControl]="viewData.form.controls.pilotingEnded"></app-mat-date-time-picker>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
            </app-input-row>
            <app-input-row>
                <mat-form-field>
                    <mat-label>Matka-ajan alkaminen</mat-label>
                    <app-mat-date-time-picker [formControl]="viewData.form.controls.travelTimeStart"></app-mat-date-time-picker>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Matka-ajan päättyminen</mat-label>
                    <app-mat-date-time-picker [formControl]="viewData.form.controls.travelTimeEnd"></app-mat-date-time-picker>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
            </app-input-row>

            <app-input-row>
                @if (viewData.form.controls.miles.controls.seaMiles.enabled) {
                    <mat-form-field>
                        <mat-label>Mailit</mat-label>
                        <input matInput type="number" [formControl]="viewData.form.controls.miles.controls.seaMiles">
                        <mat-icon matIconSuffix>nm</mat-icon>
                    </mat-form-field>
                }

                @if (viewData.form.controls.miles.controls.saimaaMiles.enabled) {
                    <mat-form-field>
                        <mat-label>Mailit Saimaalla</mat-label>
                        <input matInput type="number" [formControl]="viewData.form.controls.miles.controls.saimaaMiles">
                        <mat-icon matIconSuffix>nm</mat-icon>
                    </mat-form-field>
                }

                @if (viewData.form.controls.miles.controls.canalMiles.enabled) {
                    <mat-form-field>
                        <mat-label>Mailit kanavalla</mat-label>
                        <input matInput type="number" [formControl]="viewData.form.controls.miles.controls.canalMiles">
                        <mat-icon matIconSuffix>nm</mat-icon>
                    </mat-form-field>
                }

                @if (viewData.form.controls.waitingHours.enabled) {
                    <mat-form-field>
                        <mat-label>Odotetut tunnit</mat-label>
                        <input matInput type="number" [formControl]="viewData.form.controls.waitingHours">
                    </mat-form-field>
                }
            </app-input-row>

            <app-input-row>
                <mat-form-field>
                    <mat-label>Valmistelu</mat-label>
                    <app-edit-minutes [formControl]="viewData.form.controls.preparationTime" required></app-edit-minutes>
                    <mat-icon matIconSuffix>hourglass_empty</mat-icon>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Luotsaus</mat-label>
                    <app-edit-minutes [formControl]="viewData.form.controls.workingTime" required></app-edit-minutes>
                    <mat-icon matIconSuffix>hourglass_empty</mat-icon>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Sulkeminen</mat-label>
                    <app-edit-minutes [formControl]="viewData.form.controls.closingTime" required></app-edit-minutes>
                    <mat-icon matIconSuffix>hourglass_empty</mat-icon>
                </mat-form-field>
            </app-input-row>

            <app-input-row>
                <app-textarea-field label="Laskutuksen huomautus" [control]="viewData.form.controls.billingNotice" />
            </app-input-row>
        </app-vertical-form>
    </app-common-dialog-form>
} @else {
    <mat-spinner class="default-spinner"/>
}
