import { computed, Injectable, Signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PilotageNoticeEditorComponent, PilotageNoticeEditorParams } from "./pilotage-notice-editor/pilotage-notice-editor.component";
import { Observable, Subject } from "rxjs";
import { ApprovablePilotInfo, PecTestId, PecType, PilotageChainId, PilotageEditDetails, PilotageEndpoint, PilotageId, PilotageRouteInfo, PilotageScheduleSource, PilotageWarningResolution, PilotageWarningType, PilotType, PortCallPilotageDirection, UpdatePilotBoardingTimeParams } from "apina-frontend";
import { map } from "rxjs/operators";
import { PilotageObservationModalComponent, PilotageObservationModalComponentParams } from "./observation/pilotage-observation-modal.component";
import { UpdateEtaComponent, UpdateEtaComponentParams } from "./update-eta/update-eta.component";
import { AirDraft, awaitForClose, buildUrl, DialogsService, Duration, ErrorService, Instant, Minutes, PilotageState, PilotId, RxStompService } from "common";
import { SetPilotBoardingTimeComponent, SetPilotBoardingTimeComponentParams } from "./set-pilot-boarding-time/set-pilot-boarding-time.component";
import { MakeOrderComponent, MakeOrderComponentParams } from "./make-order/make-order.component";
import { TermsOfServiceComponent, TermsOfServiceComponentParams } from "./terms-of-service/terms-of-service.component";
import { UpdateOrderTimeComponent, UpdateOrderTimeComponentParams } from "./update-order-time/update-order-time.component";
import { TransferFeeComponent, TransferFeeComponentParams } from "./transfer-fee/transfer-fee.component";
import { CancelPilotageDialogComponent, CancelPilotageDialogComponentParams } from "./cancel-pilotage-dialog/cancel-pilotage-dialog.component";
import { RestoreToEstimateComponent, RestoreToEstimateComponentParams } from "./restore-to-estimate/restore-to-estimate.component";
import { NewPilotageSourceMode } from "./new-pilotage/new-pilotage-from.resolver";
import { ChangeVesselComponent, ChangeVesselComponentParams } from "./change-vessel/change-vessel.component";
import { ChangeCustomerComponent, ChangeCustomerComponentParams } from "./change-customer/change-customer.component";
import { ManagePilotageAlertsComponent, ManagePilotageAlertsComponentParams } from "./manage-pilotage-alerts/manage-pilotage-alerts.component";
import { AccountService } from "../account/account.service";
import { EndPilotageComponent, EndPilotageComponentParams } from "./end-pilotage/end-pilotage.component";
import { ApprovePilotageComponent, ApprovePilotageParams } from "./approve-pilotage/approve-pilotage.component";
import { AlarmClockEditorComponent, AlarmClockEditorComponentParams, AlarmClockEditorResult } from "../scheduling/alarm-clock-editor/alarm-clock-editor.component";
import { UpdateAnchorageArrivalTimeComponent, UpdateAnchorageArrivalTimeParams } from "./anchorage/update-anchorage-arrival-time.component";
import { ResetToNoticeComponent, ResetToNoticeComponentParams } from "./reset-to-notice/reset-to-notice.component";
import { SetMissingDraftComponent, SetMissingDraftComponentParams } from "./set-missing-draft/set-missing-draft.component";
import { PilotagePecTestComponent, PilotagePecTestParams } from "../pec/pilotage-pec-test/pilotage-pec-test.component";
import { EditPilotageChainComponent, EditPilotageChainParams } from "./edit-pilotage-chain/edit-pilotage-chain.component";
import { FixupPilotageComponent, FixupPilotageParams } from "./fixup-pilotage/fixup-pilotage.component";
import { openWindow } from "../window/open-window";

@Injectable({providedIn: "root"})
export class PilotageService {

    readonly pilotageUpdates$: Observable<PilotageUpdate>;
    private readonly newWindowForPilotageEdit: Signal<boolean>;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly pilotageEndpoint: PilotageEndpoint,
        private readonly dialogsService: DialogsService,
        private readonly errorService: ErrorService,
        accountService: AccountService,
        rxStompService: RxStompService,
    ) {

        this.pilotageUpdates$ = rxStompService.watch("/topic/pilotage-updates").pipe(map(msg =>
            JSON.parse(msg.body) as PilotageUpdate));

        this.newWindowForPilotageEdit = computed(() => accountService.currentUserInfo()?.newWindowForPilotageEdit ?? true);
    }

    async openCreateNewPilotageDialog(fromParams?: { from: number, mode: NewPilotageSourceMode, direction?: PortCallPilotageDirection }): Promise<void> {
        const url = buildUrl("/pilotage/new", fromParams ?? {});
        await openWindow(url, {popup: true, height: 800, width: 900});
    }

    async openCreateNewPilotageFromPortCallDialog(portCallId: number, direction: PortCallPilotageDirection): Promise<void> {
        return this.openCreateNewPilotageDialog({mode: NewPilotageSourceMode.PORTCALL, from: portCallId, direction});
    }

    openNoticeEditor(pilotageId: PilotageId): Promise<boolean> {
        const dialog = this.matDialog.open<PilotageNoticeEditorComponent, PilotageNoticeEditorParams>(PilotageNoticeEditorComponent, {
            data: {
                pilotageId
            }
        });
        return awaitForClose(dialog).then(updated => updated === true);
    }

    async openOrderDialog(pilotageId: PilotageId, target: PilotageState.ORDER | PilotageState.NOTICE, flipNewWindow: boolean = false): Promise<void> {
        const height = 640;
        const width = 950;
        if (this.openInNewWindow(flipNewWindow)) {
            this.dialogsService.openCenteredBrowserWindow(`/pilotage/${pilotageId}/make-${target.toLowerCase()}`, {width, height});
        } else {
            try {
                const details = await this.loadPilotageEditDetails(pilotageId);
                this.matDialog.open<MakeOrderComponent, MakeOrderComponentParams>(MakeOrderComponent, {
                    data: {target, details},
                    minWidth: width
                });
            } catch (e) {
                this.errorService.showLoadError(e);
            }
        }
    }

    openPilotBoardingTimeDialog(params: SetPilotBoardingTimeComponentParams): Promise<UpdatePilotBoardingTimeParams | undefined> {
        const dialog = this.matDialog.open<SetPilotBoardingTimeComponent, SetPilotBoardingTimeComponentParams, UpdatePilotBoardingTimeParams>(SetPilotBoardingTimeComponent, {data: params});
        return awaitForClose(dialog);
    }

    openMissingRequiredDraftDialog(pilotageId: PilotageId, airDraft: AirDraft | null): Promise<boolean> {
        const dialog = this.matDialog.open<SetMissingDraftComponent, SetMissingDraftComponentParams>(SetMissingDraftComponent, {
            data: {pilotageId, airDraft},
            minWidth: 520,
        });
        return awaitForClose(dialog).then(r => r ?? false);
    }

    async openEtaDialog(pilotageId: PilotageId, flipNewWindow: boolean = false): Promise<boolean> {
        const height = 800;
        const width = 950;
        if (this.openInNewWindow(flipNewWindow)) {
            await this.dialogsService.openCenteredBrowserWindow(`/pilotage/${pilotageId}/update-eta`, {width, height});
            return true;

        } else {
            try {
                const details = await this.loadPilotageEditDetails(pilotageId);
                const dialog = this.matDialog.open<UpdateEtaComponent, UpdateEtaComponentParams>(UpdateEtaComponent, {
                    data: {details},
                    minWidth: width
                });

                return awaitForClose(dialog).then(r => r ?? false);

            } catch (e) {
                this.errorService.showLoadError(e);
                throw e;
            }
        }
    }

    async openPilotageFixupDialog(pilotageId: PilotageId): Promise<void> {
        try {
            const details = await this.loadPilotageEditDetails(pilotageId);
            const dialog = this.matDialog.open<FixupPilotageComponent, FixupPilotageParams>(FixupPilotageComponent, {
                data: {details},
            });

            return awaitForClose(dialog).then(r => r ?? false);

        } catch (e) {
            this.errorService.showLoadError(e);
            throw e;
        }
    }

    private async loadPilotageEditDetails(pilotageId: PilotageId): Promise<PilotageEditDetails> {
        return this.dialogsService.showLoadingDialogFor(this.pilotageEndpoint.getPilotageEditDetails(pilotageId));
    }

    openOrderTimeDialog(pilotageId: PilotageId, state: PilotageState, vesselName: string, route: string, orderTime: Instant | null, pilotBoardingTime: Instant | null, pilotInitials: string | null, pilot2Initials: string | null, draft: number | null, source: PilotageScheduleSource): Promise<boolean> {
        const dialog = this.matDialog.open<UpdateOrderTimeComponent, UpdateOrderTimeComponentParams, boolean>(UpdateOrderTimeComponent, {data: {pilotageId, state, vesselName, route, orderTime, pilotBoardingTime, pilotInitials, pilot2Initials, source}});
        return awaitForClose(dialog).then(r => r ?? false);
    }

    openObservationDialog(pilotageId: PilotageId): void {
        this.matDialog.open<PilotageObservationModalComponent, PilotageObservationModalComponentParams>(PilotageObservationModalComponent, {data: {pilotageId}});
    }

    openApprovalDialog(pilotageId: PilotageId, pilot: ApprovablePilotInfo): void {
        this.matDialog.open<ApprovePilotageComponent, ApprovePilotageParams>(ApprovePilotageComponent, {data: {pilotageId, pilot}});
    }

    openAlertsDialog(pilotageId: PilotageId): Observable<void> {
        const changes = new Subject<void>();
        this.matDialog.open<ManagePilotageAlertsComponent, ManagePilotageAlertsComponentParams>(ManagePilotageAlertsComponent, {
            data: {
                pilotageId,
                onChange(): void {
                    changes.next();
                }
            }
        });
        return changes;
    }

    restoreToEstimate(pilotageId: PilotageId, eta: Instant, vesselName: string, route: PilotageRouteInfo): void {
        this.matDialog.open<RestoreToEstimateComponent, RestoreToEstimateComponentParams>(RestoreToEstimateComponent, {data: {pilotageId, eta, vesselName, route}});
    }

    openTermsOfServiceDialog(pilotageId: PilotageId, vesselName: string, route: string): void {
        this.matDialog.open<TermsOfServiceComponent, TermsOfServiceComponentParams>(TermsOfServiceComponent, {data: {pilotageId, vesselName, route}});
    }

    removePilotage(pilotageId: PilotageId, vesselName: string): void {
        this.dialogsService.showConfirmationDialog({question: `Poista luotsaus alukselle ${vesselName}?`, confirmText: "Poista"}, () =>
            this.pilotageEndpoint.removePilotage(pilotageId));
    }

    cancelPilotage(pilotageId: PilotageId): void {
        this.matDialog.open<CancelPilotageDialogComponent, CancelPilotageDialogComponentParams>(CancelPilotageDialogComponent, {data: {pilotageId}});
    }

    editTransferFee(pilotageId: PilotageId, transferFees: number, serviceFees: number): void {
        this.matDialog.open<TransferFeeComponent, TransferFeeComponentParams>(TransferFeeComponent, {
            data: {pilotageId, transferFees, serviceFees}
        });
    }

    editPilotageChain(pilotageChainId: PilotageChainId): void {
        this.matDialog.open<EditPilotageChainComponent, EditPilotageChainParams>(EditPilotageChainComponent, {
            data: {pilotageChainId}
        });
    }

    changeVessel(pilotageId: PilotageId): void {
        this.matDialog.open<ChangeVesselComponent, ChangeVesselComponentParams>(ChangeVesselComponent, {
            data: {pilotageId}
        });
    }

    openPilotageInfoDialog(pilotageId: PilotageId): void {
        openWindow(`/pilotage/${pilotageId}/details`, {popup: true, height: 708, width: 770, top: 0});
    }

    openPilotageComments(pilotageId: PilotageId): void {
        openWindow(`/comments/${encodeURIComponent(pilotageId)}`, {popup: true, height: 708, width: 570, top: 0});
    }

    changeCustomer(pilotageId: PilotageId): Promise<boolean> {
        const dialog = this.matDialog.open<ChangeCustomerComponent, ChangeCustomerComponentParams>(ChangeCustomerComponent, {
            data: {pilotageId}
        });
        return awaitForClose(dialog).then(r => r ?? false);
    }

    convertToUnpiloted(pilotageId: PilotageId, vesselName: string): void {
        this.dialogsService.showConfirmationDialog({question: `Haluatko muuttaa aluksen ${vesselName} luotsauksen luotsaamattomaksi?`, confirmText: "Muuta"}, () => this.pilotageEndpoint.convertToUnpiloted(pilotageId));
    }

    convertToPiloted(pilotageId: PilotageId, vesselName: string): void {
        this.dialogsService.showConfirmationDialog({question: `Haluatko muuttaa aluksen ${vesselName} luotsatuksi?`, confirmText: "Muuta"}, () => this.pilotageEndpoint.convertToPiloted(pilotageId));
    }

    startPilotage(pilotageId: PilotageId, vesselName: string): void {
        this.dialogsService.showConfirmationDialog({question: `Käynnistä aluksen ${vesselName} luotsaus?`, confirmText: "Käynnistä"}, () => this.pilotageEndpoint.startPilotage(pilotageId));
    }

    resetToOrder(pilotageId: PilotageId, vesselName: string): void {
        this.dialogsService.showConfirmationDialog({question: `Palauta aluksen ${vesselName} luotsaus tilaukseksi?`, confirmText: "Palauta tilaukseksi"}, () => this.pilotageEndpoint.resetToOrder(pilotageId));
    }

    resetToNotice(pilotageId: PilotageId, eta: Instant, vesselName: string, route: PilotageRouteInfo): void {
        this.matDialog.open<ResetToNoticeComponent, ResetToNoticeComponentParams>(ResetToNoticeComponent, {data: {pilotageId, eta, vesselName, route}});
    }

    completePilotage(pilotageId: PilotageId): void {
        this.matDialog.open<EndPilotageComponent, EndPilotageComponentParams>(EndPilotageComponent, {
            data: {
                pilotageId
            }
        });
    }

    suspendPilotage(pilotageId: PilotageId, vesselName: string): void {
        this.dialogsService.showConfirmationDialog({question: `Keskeytä aluksen ${vesselName} luotsaus?`, confirmText: "Keskeytä"}, () => this.pilotageEndpoint.suspendPilotage(pilotageId));
    }

    continuePilotage(pilotageId: PilotageId, vesselName: string): void {
        this.dialogsService.showConfirmationDialog({question: `Jatka aluksen ${vesselName} luotsausta?`, confirmText: "Jatka"}, () => this.pilotageEndpoint.continuePilotage(pilotageId));
    }

    private openInNewWindow(flip: boolean): boolean {
        const newWindow = this.newWindowForPilotageEdit();
        return flip ? !newWindow : newWindow;
    }

    editAlarmTime(pilotageId: PilotageId, pilotId: PilotId, pilotType: PilotType, startTime: Instant, preparationTime: Duration | null): Promise<Minutes | undefined> {
        const dialog = this.matDialog.open<AlarmClockEditorComponent, AlarmClockEditorComponentParams, AlarmClockEditorResult>(AlarmClockEditorComponent, {
            data: {
                pilotageId, pilotId, pilotType, startTime, preparationTime
            }
        });
        return awaitForClose(dialog);
    }

    markAsArrivedToAnchor({pilotageId, vesselName}: { pilotageId: PilotageId, vesselName: string }): void {
        this.dialogsService.showConfirmationDialog({question: `Merkitse ${vesselName} saapuneeksi ulkoankkuriin?`, confirmText: "Saapunut ulkoankkuriin"}, () =>
            this.pilotageEndpoint.markAsArrivedToAnchor(pilotageId));
    }

    removeAnchorage({pilotageId, vesselName}: { pilotageId: PilotageId, vesselName: string }): void {
        this.dialogsService.showConfirmationDialog({question: `Poista aluksen ${vesselName} ulkoankkuritieto?`, confirmText: "Alus ei saavu ulkoankkuriin"}, () =>
            this.pilotageEndpoint.removeAnchorage(pilotageId));
    }

    updateAnchorageArrivalTime(params: UpdateAnchorageArrivalTimeParams): void {
        this.matDialog.open<UpdateAnchorageArrivalTimeComponent, UpdateAnchorageArrivalTimeParams>(UpdateAnchorageArrivalTimeComponent, {
            data: params
        });
    }

    createPilotagePecTest(pilotageId: PilotageId): void {
        this.matDialog.open<PilotagePecTestComponent, PilotagePecTestParams>(PilotagePecTestComponent, {
            data: {mode: "create", pilotageId}
        });
    }

    updatePilotagePecTest(id: PecTestId, type: PecType): Promise<boolean> {
        const dialog = this.matDialog.open<PilotagePecTestComponent, PilotagePecTestParams>(PilotagePecTestComponent, {
            data: {mode: "update", id, type}
        });
        return awaitForClose(dialog).then(r => r ?? false);
    }

    toggleConfidentiality(pilotageId: PilotageId, confidential: boolean): void {
        const action = confidential ? "Piilota" : "Näytä";

        this.dialogsService.showConfirmationDialog({question: `${action} luotsaus julkisessa luettelossa?`, confirmText: action},
            () => this.pilotageEndpoint.toggleConfidentiality(pilotageId, confidential));
    }

    resolveTwoPilotsWarning(pilotageId: PilotageId): void {
        this.dialogsService.showConfirmationDialog({question: `Kuittaa varoitus kahden luotsin vaateesta?`, confirmText: "Kuittaa"},
            () => this.pilotageEndpoint.resolvePilotageWarning(pilotageId, PilotageWarningType.REQUIRES_TWO_PILOTS, PilotageWarningResolution.PROCESSED));
    }

    resolveDaylightWarning(pilotageId: PilotageId): void {
        this.dialogsService.showConfirmationDialog({question: `Kuittaa daylight-olosuhde?`, confirmText: "Kuittaa"},
            () => this.pilotageEndpoint.resolvePilotageWarning(pilotageId, PilotageWarningType.REQUIRES_DAYLIGHT, PilotageWarningResolution.PROCESSED));
    }
}

// TODO: import from apina
export interface PilotageUpdate {
    user: string;
    pilotageId: PilotageId;
}
