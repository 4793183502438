import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { PilotageId, PilotageObservationEndpoint, PilotageObservationForEdit } from "apina-frontend";
import { ErrorService } from "common";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { PilotageObservationComponent } from "./pilotage-observation.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
    templateUrl: './pilotage-observation-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        PilotageObservationComponent,
    ]
})
export class PilotageObservationModalComponent {
    readonly observation = signal<PilotageObservationForEdit | undefined>(undefined);

    constructor(
        @Inject(MAT_DIALOG_DATA) params: PilotageObservationModalComponentParams,
        private readonly dialogRef: MatDialogRef<PilotageObservationModalComponent>,
        endpoint: PilotageObservationEndpoint,
        errorService: ErrorService,
    ) {

        endpoint.getPilotageObservation(params.pilotageId).then(
            o => this.observation.set(o),
            e => errorService.showLoadError(e)
        );
    }

    close(): void {
        this.dialogRef.close();
    }
}

export interface PilotageObservationModalComponentParams {
    pilotageId: PilotageId;
}
